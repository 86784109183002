import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

import translationPolish from "./translations/pl/translation.json";
import translationEnglish from "./translations/en/translation.json";

const resources = {
  pl: { translation: translationPolish },
  en: { translation: translationEnglish }
}

export const availableLanguages = Object.keys(resources)

i18n
  // .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    returnNull: false,
    resources,
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

export {};