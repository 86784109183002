import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Offer from "./components/offer/Offer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProjectGrid from "./components/projectGrid/ProjectGrid";
import "./app.css";

function App() {

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path={"/"} element={<ProjectGrid />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/offer"} element={<Offer />} />
        <Route path={"/contact"} element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
