import React, { useState, useEffect, useRef } from "react";
import insta from "../../img/insta.svg";
import "./navbar.css"
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

export default function Navbar() {
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const sections = ["designs", "about", "offer", "contact"];
  const [activeSection, setActiveSection] = useState(sections[0]);

  useEffect(() => {
    /* Close the drawer when the user clicks outside of it */
    const closeDrawer = (e: any) => {
      // @ts-ignore
      if (openDrawer && !drawerRef.current.contains(e.target)) {
        toggleDrawer(false);
        return;
      }
    }
    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);

  },)

  return (
    <header>
      <div className={"n"}>
        <nav className={"n-list"} id={"myTopnav"}>
          {/* <NavLink to={"/"}> */}
          <NavLink className={"n-title"} to={"/"}>
            {/* <img className={"n-logo"} src={logo} alt="logo" /> */}
            Aleksandra Hereć
          </NavLink>
          <div className={openDrawer ? "n-link-open" : "n-link"} ref={drawerRef}>
            <NavLink
              to={"/"}
              className={activeSection === sections[0] ? "n-text-underline" : "n-text"}
              onClick={() => { toggleDrawer(false); setActiveSection(sections[0]) }}
            >
              {t("designs")}
            </NavLink>
            <a href={"http://www.quiz.aleksandraherec.pl/"} target={"_blank"} className={"n-text"}
              rel="noreferrer" onClick={() => toggleDrawer(false)}>
              {t("style-quiz")}
            </a>
            <NavLink
              to={"/about"}
              className={activeSection === sections[1] ? "n-text-underline" : "n-text"}
              onClick={() => { toggleDrawer(false); setActiveSection(sections[1]) }}>
              {t("about")}
            </NavLink>
            <NavLink
              to={"/offer"}
              className={activeSection === sections[2] ? "n-text-underline" : "n-text"}
              onClick={() => { toggleDrawer(false); setActiveSection(sections[2]) }}>
              {t("offer")}
            </NavLink>
            <NavLink
              to={"/contact"}
              className={activeSection === sections[3] ? "n-text-underline" : "n-text"}
              onClick={() => { toggleDrawer(false); setActiveSection(sections[3]) }}>
              {t("contact")}
            </NavLink>
            <LanguageSelector />
            <a href={"https://www.instagram.com/aleksandra.herec/"} target={"_blank"} rel="noreferrer" onClick={() => toggleDrawer(false)}>
              <img src={insta} alt={"insta"} className={"n-icon-insta"}/>
            </a>
          </div>
          <div className="n-icon" onClick={() => toggleDrawer(true)}>
            <FaBars />
          </div>
        </nav>
        <hr className={"hr"}/>
      </div>
    </header>
  );
}
