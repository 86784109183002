import React from "react";
import "./offer.css";
import offer1 from "../../img/offer-1.jpg"
import offer2 from "../../img/offer-2.jpg"
import offer3 from "../../img/offer-3.jpg"
import { useTranslation } from "react-i18next";

function Offer() {
  const { t } = useTranslation();
  return (
    <>
      <div className={"o"}>
        <div className="o-center">
            <p className={"o-text"}>
            {t("offer-text-top")}
            </p>
        </div>
        <div className={"o-left"}>
            <img src={offer1} alt={"offer-img"} className={"o-img"}/>
        </div>
        <div className={"o-right"}>
          <h2 className={"o-title"}>{t("private-interiors")}</h2>
          <img src={offer1} alt={"offer-img"} className={"o-img-shown-phone"}/>
          <p className={"o-text"}>{t("offer-text1")}</p>
          <hr/>
          <p className={"o-tags"}>{t("offer-tags1")}</p>
          <hr/>
        </div>
        <div className={"o-left"}>
          <div className={"o-card"}>
          <h2 className={"o-title"}>{t("commercial-interiors")}</h2>
          <img src={offer2} alt={"offer-img"} className={"o-img-shown-phone"}/>
          <p className={"o-text"}>{t("offer-text2")}</p>
          <hr/>
          <p className={"o-tags"}>{t("offer-tags2")}</p>
          <hr/>
          </div>
        </div>
        <div className={"o-right"}>
            <img src={offer2} alt={"offer-img"} className={"o-img"}/>
        </div>
        <div className={"o-left"}>
            <img src={offer3} alt={"offer-img"} className={"o-img"}/>
        </div>
        <div className={"o-right"}>
          <h2 className={"o-title"}>{t("architecture")}</h2>
          <img src={offer3} alt={"offer-img"} className={"o-img-shown-phone"}/>
          <p className={"o-text"}>{t("offer-text3")}</p>
          <p className={"o-text"}>{t("offer-text3-1")}</p>
          <hr/>
          <p className={"o-tags"}>{t("offer-tags3")}</p>
          <hr/>
        </div>
      </div>
    </>
  );
}

export default Offer