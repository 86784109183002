import "./about.css";
import team from "../../img/team.jpg"
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  return (
    <div className={"a"}>
      <div className={"a-left"}>
        <div className={"a-card-carousel"}>
        <img src={team} alt={"about-team"} className={"a-img"} />
        </div>
        <div className={"a-card"}>
            <img src={team} alt={"about-team"} className={"a-img-phone"} />
        </div>
      </div>
      <div className={"a-right"}>
        {/* <div className={"a-card"}> */}
          <p className={"a-text"}>{t("about-text1")}</p>
          <p className={"a-text"}>{t("about-text2")}</p>
          <p className={"a-text"}>{t("about-text3")}</p>
          <p className={"a-text"}>{t("about-text4")}</p>
          <p className={"a-text"}>{t("about-text5")}</p>
          <p className={"a-text"}>{t("about-text6")}</p>
          <br />
          <br />
          <br />
        {/* </div> */}
      </div>
    </ div>
  );
}

export default About