import i18n from "../i18n/i18n";
import "./navbar.css"
import { useTranslation } from "react-i18next";


  const languageChange = (e:any) => {
    i18n.changeLanguage( e.target.value);
  } 

  export default function LanguageSelector () {
    const { i18n } = useTranslation();
    return (
        <div className={"n-languages"}>
         <button className={i18n.language === "pl" ? "n-b-active" : "n-b-unactive"} onClick={languageChange} value={"pl"}>pl</button>
         <p className="n-text-lang">|</p>
         <button className={i18n.language === "en" ? "n-b-active" : "n-b-unactive"} onClick={languageChange} value={"en"}>en</button>
        </div>
      )
 }

