import React, {useState} from "react";
import "./contact.css";
import phone from "../../img/phone.svg";
import home from "../../img/home.svg";
import email from "../../img/email.svg";
import photo from "../../img/projects/i038sp.webp";
import emailJs from "emailjs-com";
import { useTranslation } from "react-i18next";

function Contact() {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [done, setDone] = useState(false);
  const { t } = useTranslation();
  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    if (formRef.current) {
      emailJs.sendForm(
          "service_4116kf9",
          "template_ulvzjdf",
          formRef.current,
          "user_KcNX5noAadDd86cYo2OwQ")
      .then((result) => {
        console.log(result.text);
        setDone(true);
      }, (error) => {
        console.log(error.text);
      });
    }
  }

  return (
      <div className={"c"}>
        {/*<div className={"c-wrapper"}>*/}
          <div className={"c-left"}>
            <div className={"c-card"}>
              <img src={photo} alt={"contact-img"} className={"c-img"}/>
            </div>
          </div>
          <div className={"c-right"}>
            <h1 className={"c-title"}>{t("contact-us")}</h1>
            <div className={"c-info"}>
              <div className={"c-info-item"}>
                <img src={home} alt={"address"} className={"c-icon"}/>
                ul. Tyszkiewicza 11a/6, 01-111 Warszawa
              </div>
              <div className={"c-info-item"}>
                <img src={email} alt={"mail"} className={"c-icon"}/>
                biuro@aleksandraherec.pl
              </div>
              <div className={"c-info-item"}>
                <img src={phone} alt={"phone"} className={"c-icon"}/>
                519 059 056
              </div>
            </div>
            <br />
            <div>{t("contact-send-email")}</div>
            <form ref={formRef} onSubmit={handleSubmit}>
              <input type={"text"} placeholder={t("name")} name={"user_name"}/>
              <input type={"text"} placeholder={t("topic")} name={"user_subject"}/>
              <input type={"text"} placeholder={t("email")} name={"user_email"}/>
              <textarea rows={5} placeholder={t("text")} name={"message"}/>
              <br/>
              {!done && <button>{t("contact-send")}</button>}
              {done && <button disabled={true}>{t("contact-sent")}</button>}
            </form>
          </div>
        {/*</div>*/}
      </div>
  );
}
export default Contact